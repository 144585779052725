<template>
  <v-card class="add-company">
    <v-card-title>{{ edit ? "Edit" : "Add" }} Company</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.name"
              :rules="[rules.requiredRule]"
              color="secondary"
              label="Company Name"
            >
            </v-text-field>
          </v-col>
          <!-- <v-col md="6" cols="12">
            <v-text-field
              v-model="form.career_name"
              :rules="[rules.requiredRule]"
              label="Career Portal Name"
              color="secondary"
              suffix=".airec.io"
            >
            </v-text-field>
          </v-col> -->
          <v-col md="6" cols="12">
            <!-- <v-text-field
              v-model="form.contact_name"
              :rules="[rules.requiredRule]"
              label="Contact Name"
              color="secondary"
            >
            </v-text-field> -->
            <v-text-field
              v-model="form.email"
              :rules="[rules.requiredRule, rules.emailRule]"
              label="Contact Email"
              color="secondary"
            >
            </v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <!-- <v-text-field
              v-model="form.email"
              :rules="[rules.requiredRule, rules.emailRule]"
              label="Contact Email"
              color="secondary"
            >
            </v-text-field> -->
            <v-text-field
              v-model="form.contact"
              :rules="[rules.requiredRule]"
              label="Contact Phone"
              color="secondary"
            >
            </v-text-field>
          </v-col>

          <!-- <v-col md="6" cols="12"> -->
          <!-- <v-text-field
              v-model="form.contact"
              :rules="[rules.requiredRule]"
              label="Contact Phone"
              color="secondary"
            >
            </v-text-field> -->
          <!-- </v-col> -->

          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.address"
              :rules="[rules.requiredRule]"
              label="Address"
              color="secondary"
            >
            </v-text-field>
          </v-col>

          <!-- <v-col md="6" cols="12">
            <v-select
              v-model="form.package"
              :items="packages"
              :rules="[rules.requiredRule]"
              label="Package"
              color="secondary"
              item-text="package_title"
              item-value="_id"
              @change="packageChange"
            ></v-select>
          </v-col> -->
          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.leaderboard')"
              autocomplete
              cache-items
              required
              :items="leaderboard"
              :rules="[rules.requiredRule]"
              v-model="form.leaderboard"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.calendar_notifications')"
              autocomplete
              cache-items
              required
              :items="calendar_notifications"
              :rules="[rules.requiredRule]"
              v-model="form.calendar_notifications"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.evaluations')"
              autocomplete
              cache-items
              required
              :items="evaluations"
              :rules="[rules.requiredRule]"
              v-model="form.evaluations"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.advance_dashboard')"
              autocomplete
              cache-items
              required
              :items="advance_dashboard"
              :rules="[rules.requiredRule]"
              v-model="form.advance_dashboard"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.emails')"
              autocomplete
              cache-items
              required
              :items="emails"
              :rules="[rules.requiredRule]"
              v-model="form.emails"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              :label="$vuetify.lang.t('$vuetify.search')"
              autocomplete
              cache-items
              required
              :items="search"
              :rules="[rules.requiredRule]"
              v-model="form.search"
              class="mt-4 half-width-input"
            >
            </v-select>
          </v-col>

          <!-- <v-col md="6" cols="12">
            <v-menu
              :close-on-content-click="true"
              v-model="menu"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  label="Billing Start Date"
                  :rules="[rules.requiredRule]"
                  v-model="form.billing_start_date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                autosave
                v-model="form.billing_start_date"
                no-title
                hide-details
              ></v-date-picker>
            </v-menu>
          </v-col> -->

          <!-- <v-col md="6" cols="12">
            <v-select
              v-model="form.billing_interval"
              :rules="[rules.requiredRule]"
              :items="billingIntervals"
              label="Billing Interval"
              color="secondary"
              item-text="label"
              item-value="value"
            ></v-select>
          </v-col> -->

          <!-- <v-col cols="12">
            <v-textarea
              v-model="form.comments"
              label="Additional Comments"
              color="secondary"
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col> -->
        </v-row>

        <!-- <h2 class="mb-3">Add Ons</h2>
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="6"
            v-for="feature in features"
            :key="feature"
          >
            <v-switch
              inset
              color="secondary"
              :label="feature"
              :value="form.features.includes(feature)"
              @change="(e) => addOnChange(e, feature)"
            ></v-switch>
          </v-col>
        </v-row> -->

        <!-- <v-row>
          <v-col cols="12" md="6">
            <ImagePicker
              :src="form.logo"
              placeholder="Upload Logo"
              @update="(data) => imageHandler('logo', data)"
              @remove="() => imageRemoveHandler('logo')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <ImagePicker
              :src="form.cover"
              placeholder="Upload Cover Photo"
              @update="(data) => imageHandler('cover', data)"
              @remove="() => imageRemoveHandler('cover')"
            />
          </v-col>
        </v-row> -->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="error" @click="closeDialog">Close</v-btn>
      <v-btn color="secondary" @click="formHandler"
        >{{ edit ? "Update" : "Create" }} Company</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { requiredRule, emailRule } from "../../helpers/form-rules";
// import ImagePicker from "../image-picker/image-picker.vue";

import { createNamespacedHelpers } from "vuex";
const packageModule = createNamespacedHelpers("package");
export default {
  name: "admin-add-company",
  props: {
    edit: { type: Boolean, default: false },
    companyDetail: { type: Object },
  },
  // components: { ImagePicker },
  data() {
    return {
      isValid: false,
      rules: {
        requiredRule,
        emailRule,
      },
      form: {
        name: null,
        career_name: null,
        contact_name: null,
        email: null,
        contact: null,
        address: null,
        package: null,
        comments: null,
        billing_start_date: null,
        billing_interval: null,
        // features
        features: [],
        // logos
        logo: "",
        cover: "",
        leaderboard: null,
        calendar_notifications: null,
        evaluations: null,
        advance_dashboard: null,
        emails: null,
        search: null,
      },
      logoFile: null,
      coverFile: null,
      menu: false,
      leaderboard: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
      calendar_notifications: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
      evaluations: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
      advance_dashboard: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
      emails: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
      search: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "true" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "false" },
      ],
    };
  },
  computed: {
    ...packageModule.mapState(["packages", "features"]),
    billingIntervals() {
      return [
        {
          label: "Monthly",
          value: "monthly",
        },
        {
          label: "Quarterly",
          value: "quarterly",
        },
        {
          label: "Biannual",
          value: "biannual",
        },
        {
          label: "Annual",
          value: "annual",
        },
      ];
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-model");
      this.$emit("close");
      this.$emit("refresh");
      this.loading = false;
    },
    imageHandler(type, { file, src }) {
      if (type === "logo") {
        this.logoFile = file;
        this.form.logo = src;
      } else {
        this.coverFile = file;
        this.form.cover = src;
      }
    },
    imageRemoveHandler(type) {
      if (type === "logo") {
        this.logoFile = null;
        this.form.logo = null;
      } else {
        this.coverFile = null;
        this.form.cover = null;
      }
    },
    async editCompany(payload) {
      try {
        let response = await this.axios.put(
          `/company/${this.companyDetail.id}`,
          payload
        );
        if (response.status === 200) {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Company created successfully!",
          });
          this.closeDialog();
        } else {
          this.$notify({
            group: "backend-error",
            type: "error",
            title: "Validation Error",
            text: "Something went wrong. Please try again later.",
          });
        }
      } catch (error) {
        this.loading = false;
        this.error_alert = true;
        this.error_message = "Something went wrong ";
      }
    },
    async formHandler() {
      await this.$refs.form.validate();
      if (this.isValid) {
        const payload = {
          name: this.form.name,
          email: this.form.email,
          address: this.form.address,
          contact: this.form.contact,
          emails: this.form.emails === "true" ? true : false,
          search: this.form.search === "true" ? true : false,
          email_address: this.form.email,
          calendar_notifications:
            this.form.calendar_notifications === "true" ? true : false,
          evaluations: this.form.evaluations === "true" ? true : false,
          leaderboard: this.form.leaderboard === "true" ? true : false,
          advanceDashboard:
            this.form.advance_dashboard === "true" ? true : false,
        };

        if (this.companyDetail && this.companyDetail.id) {
          this.editCompany(payload);
          return;
        }

        let response = await this.axios.post("/company", payload);
        if (response.status === 200) {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Company created successfully!",
          });
          this.$emit("close");
          this.$router.go();
        } else {
          this.$notify({
            group: "backend-error",
            type: "error",
            title: "Validation Error",
            text: "Something went wrong. Please try again later.",
          });
        }
      } else {
        this.$notify({
          group: "backend-error",
          type: "error",
          title: "Validation Error",
          text: "Some fields are empty",
        });
      }
    },
    addOnChange(active, feature) {
      if (active) {
        this.form.features.push(feature);
      } else {
        this.form.features = this.form.features.filter((f) => f !== feature);
      }
    },
    packageChange(pkg_id) {
      const pkg = this.packages.find((p) => p._id == pkg_id);
      const { features } = pkg;
      this.form.features = [...features];
    },
  },
  created() {
    if (Object.keys(this.companyDetail).length > 0) {
      (this.form.name = this.companyDetail.name),
        (this.form.email = this.companyDetail.email),
        (this.form.address = this.companyDetail.address),
        (this.form.contact = this.companyDetail.contact),
        (this.form.emails =
          this.companyDetail.emails === true ? "true" : "false"),
        (this.form.search =
          this.companyDetail.search === true ? "true" : "false"),
        (this.form.email = this.companyDetail.email),
        (this.form.calendar_notifications =
          this.companyDetail.calendar_notifications === true
            ? "true"
            : "false"),
        (this.form.evaluations =
          this.companyDetail.evaluations === true ? "true" : "false"),
        (this.form.leaderboard =
          this.companyDetail.leaderboard === true ? "true" : "false"),
        (this.form.advance_dashboard =
          this.companyDetail.advanceDashboard === true ? "true" : "false"),
        (this.form.career_name = this.companyDetail.url),
        (this.form.id = this.companyDetail.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.add-company {
  &::v-deep .v-input {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
